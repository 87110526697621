<template>
  <div v-if="localeStore?.isUsLocale" class="firestarters-banner desk-hor-mob-col">
    <CloudinaryImage
      v-if="state.logoImg"
      :public-id="state.logoImg"
      :options="{ width: 132, height: 132, quality: 'auto:eco' }"
      :alt="title"
      :image-classes-override="['logo']"
    />
    <div class="content-section">
      <div class="h4">
        {{ title }}
      </div>
      <div v-if="description" class="desc">
        {{ description }}
      </div>
      <CustomButton
        v-if="link_and_button_text.href"
        :is-small="true"
        :href="link_and_button_text.href"
        style-color="white"
      >
        {{ link_and_button_text.title }}
      </CustomButton>
    </div>
    <CloudinaryImage
      v-if="state.bgImg"
      :public-id="state.bgImg"
      :options="{ width: 380, height: 240, quality: 'auto:eco' }"
      :alt="title"
      :image-classes-override="['desk-only', 'bg-img']"
    />
  </div>
</template>

<script setup lang="ts">
import { AssetCloudinary } from '~/types/contentstack';
import { getAssetPublicId } from '~/util/contentstack/csHelpers';
import { useLocaleStore } from '~/store/locale';

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  logo: {
    type: Array as () => AssetCloudinary[],
    required: true,
  },
  image: {
    type: Array as () => AssetCloudinary[],
    required: true,
  },
  description: {
    type: String,
    default: undefined,
  },
  // eslint-disable-next-line vue/prop-name-casing
  link_and_button_text: {
    type: Object as () => {
      href: string;
      title: string;
    },
    default: () => ({
      href: '',
      title: '',
    }),
  },
});

const state = reactive({
  logoImg: getAssetPublicId(props.logo),
  bgImg: getAssetPublicId(props.image),
});

const localeStore = useLocaleStore();
</script>
<style lang="scss" scoped>
.firestarters-banner {
  align-items: center;
  width: 100%;
  background-color: $color-neutral-cool-900;
  min-height: 240px;
  :deep(.logo) {
    height: 69px;
    width: 69px;
  }
  .desc {
    margin-bottom: 1.5rem;
    line-height: 150%;
  }
  .content-section {
    color: $color-neutral-white;
  }
  @include local-mixins.tablet_and_mobile {
    padding: 3rem 1rem;
    .content-section {
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
    }
    :deep(.logo) {
      margin-bottom: 1.5rem;
    }
  }
  @include local-mixins.desktop {
    border-radius: 8px;
    :deep(.logo) {
      height: 132px;
      width: 132px;
    }
    :deep(.logo),
    .content-section {
      margin: 3rem;
    }
    :deep(.bg-img) {
      margin-right: 116px;
      width: #{local-functions.rem-calc(380)};
      height: auto;
    }
    .btn {
      width: fit-content;
    }
  }
}
</style>
